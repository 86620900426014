<template>
	<!--自查表 -->
  <div class="container" style="padding: 20px;">
    <GModule>
		<div class="condition-box">
			<div class="state-box">
				<p>选择项目</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleClick">
							<a-menu-item v-for="(item,index) in projectList" :key="index">{{item.name}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button v-if="projectList&&projectList.length > 0">
						{{projectList[projectIndex].name}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
				<p>状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleStaClick">
							<a-menu-item v-for="(sta,staIndex) in statusList" :key="staIndex">{{sta}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{statusListIndex == 'all' ? '全部' : statusList[statusListIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<!-- <a-button class="btn clo2" @click="anymoreClick()">批量完成自查</a-button> -->
		</div>
		<div class="condition-box">
			<div class="state-box">
				<a-tabs
					class="tabbox"
					v-model:activeKey="activeKey" 
					v-if="tabList.length > 0">
					<a-tab-pane 
						v-for="(i,index) in tabList" 
						:key="index" 
						:tab="i.name"></a-tab-pane>
				</a-tabs>
			</div>
			<!-- <a-button class="btn clo3" @click="search()">查询</a-button> -->
		</div>
		<a-modal v-if="isOk" :visible="isOk" title="确认批量完成自查吗？" @ok="ok()" @cancel="cancel()">
			<div v-text="okText"></div>
		</a-modal>
		<a-table
			class="ttable"
			:columns="columnsHe" 
			:data-source="data" 
			:scroll="{ x: 500 }"
			 bordered
			rowKey='id'
			:row-selection="{ columnWidth:25,selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
			:pagination="false">
			  <template #bodyCell="{ column ,record,index}">
				  <template v-if="column.key === 'operation'">
					<div class="caozuo">
						<p
							:style="record.status == 'complete' ? 'color:#999999;':''" 
							@click="record.status == 'wait' ? checkClick(record.id,index): false">完成自查</p>
					</div>
				  </template>
			  </template>
		</a-table>
		<div class="documentFt" style="margin-top: 10px;">
		  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
		</div>
    </GModule>
  </div>
</template>
<script>
export default {
  name: 'SelfCheck',
  data(){
    return {
		selectedRowKeys:[],
		 projectList:[],
		 projectIndex:0,
		statusList:{}, // 状态列表
		statusListIndex:'all',
		columnsHe: [{
		    title: '序号',
		    width: 60,
		    dataIndex: 'id',
		    fixed: 'left'
		  }, {
		    title: '描述',
		    width: 250,
		    dataIndex: 'content_data'
		  }, {
			title: '备注',
			width: 150,
			dataIndex: 'remark'
		  //  }, {
		  //   title: '状态',
		  //   width: 70,
		  //   dataIndex: 'status_text'
		  // },{
		  //   title: '操作',
		  //   key: 'operation',
		  //   fixed: 'right',
		  //   width: 60,
		}],
		data: [],
		limit:10,
		page:1,
		total:0 ,// 总条数
		isOk:false,
		okArr:[] ,// 可完成自查的数组
		okText:'',
		activeKey:0,
		tabList:[] 
    }
  },
  computed: {
  	proId() {
  	  return this.$store.state.proId
  	}
  },
  watch:{
	activeKey(val,old){
		this.search()
	}  
  },
  async created() {
	  // 获取项目列表
	  let pro = await this.$utils.api.post({
	    url:'/project/my_selectpage',
	    result:1
	  })
	  if(pro.code == 1){
		  this.projectList = pro.data.list;
		  if(this.projectList&&this.projectList.length > 0){
			  // 初始选中项目
			  for(let i in  this.projectList){
				  if(this.projectList[i].id == this.proId){
					  this.projectIndex = i
				  }
			  }
		  }
	  }
	
	// 获取状态
	let r = await this.$utils.api.get({
		url:'/selfs/setting',
		result:1
	})
	if(r.code == 1){
		let a = {all:"全部"}
		this.statusList = {...a,...r.data.statusList}
		this.tabList = r.data.typeList
	}
	// 初始化列表
	this.getList()
  },
  methods:{
	  handleClick(e){
		  this.projectIndex = e.key
		  // 更新系统选中的项目
		  this.$store.commit('setStore', {
		    name: 'proId', 
		    data: this.projectList[e.key].id
		  })
		  this.statusListIndex = 'all'
		  this.activeKey = 0
		  this.search()
	  },
	  handleStaClick(e){
	  	this.statusListIndex = e.key
		this.search()
	  },
	  pageChange(page,pageSize){
	  	// 切换页码时查询列表
	  	this.page = page
	  	this.getList()
	  },
	  search(){
	  	// 根据条件查询列表
	  	this.page = 1 //每次点击查询按钮都要重置页码为1
	  	this.getList()
	  },
	  async getList(){
		// 请求列表
		let type = 0
		if(this.tabList.length > 0){
			type = this.tabList[this.activeKey].id // 分类id
		}
		
		let info = {
			limit:this.limit,
			page:this.page,
			type_id:type
		}

		if(this.statusListIndex != 'all'){ // 状态
			info.status = this.statusListIndex
		}
	  	let rr = await this.$utils.api.post({
	  		url:'/selfs/index',
	  		data:info,
	  		result:1
	  	})
	  	if(rr.code == 1){
	  		this.data = rr.data.rows
			this.total = rr.data.total
	  	}
	  },
		async checkClick(id,index){
			// 完成自查
			let res = await this.$utils.api.post({
				url:'/selfs/complete',
				data:{
					ids:id
				},
				result:1
			})
			if(res.code == 1){
				this.data[index].status = 'complete'
				this.data[index].status_text = '已自查'
				// 批量自查重置
				this.isOk = false
				this.selectedRowKeys = []
				this.okArr = []
				this.okText = ''
			}
		},
		onSelectChange(arr){
			this.selectedRowKeys = arr;
		},
		anymoreClick(){
			// 点击批量完成自查
			if(!this.selectedRowKeys.length){
				return this.$utils.msgSuc('没有选中自查行')
			}
			let row = []
			let ids = []
			// 判断获取批量选中中可完成自查的数据
			for(let i in this.data){
				for(let j in this.selectedRowKeys){
					if(this.data[i].id == this.selectedRowKeys[j]){
						if(this.data[i].status == 'wait'){
							row.push({id:this.data[i].id,index:i})
							ids.push(this.data[i].id)
						}
					}
				}
			}
			if(row.length > 0){
				this.isOk = true
				this.okArr = row
				this.okText = '未完成自查的行ID为：'+ ids.join(',') +'，是否批量完成自查？'
			}else{
				this.isOk = false
				return this.$utils.msgSuc('暂无没有完成自查的行')
			}
		},
		async ok(){
			// 确认批量完成自查
			for(let i in this.okArr){
				this.checkClick(this.okArr[i].id,this.okArr[i].index)
			}
		},
		cancel(){
			// 取消批量完成自查
			this.isOk = false
			this.selectedRowKeys = []
			this.okArr = []
			this.okText = ''
		}
  }

}
</script>

<style scoped lang="scss">
.condition-box{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 5px 0 10px 0;
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}
.tabbox{
	margin-left: 20px;
}

</style>